import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import imgS1 from "../assets/image/png/herups-poster.png";
import imgS2 from "../assets/image/png/herups-problem-statement.png";

import { device } from "../utils";

import "../assets/fonts/icon-font/css/style.css";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">user design</Text>
                <Title variant="secSm" className="my-4">
                  Higher Education Resources for Underprivileged Students{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  As I approached towards the end of my college journey, I had one final project that would put all of the
                  skills and knowledge that I've gained up to the challenge. This capstone project involved forming a team  
                  and using our expertise in people, information, and technology to develop and design a solution to a 
                  real-world information problem.
                  <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                    style={{marginTop: 15}}
                  >
                    Our team committed to design a solution that helps students in the South Seattle area find resources 
                    tailored towards their own circumstances. We come from similar backgrounds as the students we want to
                    help so our project is aimed to empower those students to take action towards higher education.
                  </Text>
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Roles</Text>
                <Title variant="cardBig" className="mt-3">
                  UX Researcher &<br/> UX Designer
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Jan 2020 - Jun 2020
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://notlih.github.io/HERUPS/#/" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>Live work</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="11" className="mb-2" style={{marginTop: -25}}>
                <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1614253862/HERUPS/poster.png"} alt="Project Poster" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4" style={{marginTop: -50}}>
              Project Process
            </Title>
            <Title variant="cardBig" className="mb-4" style={{marginTop: -30}}>
              01. Define
            </Title>
            <Text variant="p" className="mb-4">
              First, we brainstormed ideas and narrowed down the problem space we would like to work in. 
              Our second step was to formulate a concise definition of the problem that we are trying to solve.
              The definition included our
              <span style={{ color: '#e69138', fontWeight: "bold" }}> audience</span>, 
              <span style={{ color: '#618d4c', fontWeight: "bold" }}> goal</span>, and
              <span style={{ color: '#3c78d8', fontWeight: "bold" }}> measurable impact</span>.
            </Text>
            <Row>
              <Col xs="8" className="mb-5">
                <img src={imgS2} alt="problem statement" className="img-fluid w-100"/>
              </Col>
            </Row>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 10}}>
              02. Research
            </Title>
            <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
              The next part of our process was to create a research plan. The research plan consisted of user research, market
              research, and a literature review. Our research helped us gain deeper insights, fresh ideas, and unforeseen
              possibilities.
            </Text>
            <Title variant="card" className="mb-3">
              User Research
            </Title>
            <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
              For our user research, I was in charge of managing all of the tasks. The first thing that we did was to create a 
              <a href="https://forms.gle/EaPb1yGLXFPWC7sH7" target="_blank"> survey </a>through Google Forms. The purpose of this
              survey was to collect resources used by former and current high school students in their process towards higher
              education. Each resource was verified for its relevance and added onto our website during our development process.
              Lastly, we reached out to high school counselors and conducted interviews to help us understand our target
              users' needs.
              <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                Through the interviews, we learned how important it was to remove pre-existing mental barriers that students
                have put up for themselves (eg.,
                <span style={{ fontStyle: "italic" }}> “I can’t pursue a higher education because of (blank)” </span>or
                <span style={{ fontStyle: "italic" }}> “My situation is too difficult, there are no resources out there for me”).</span>
              </Text>
            </Text>
            <Title variant="card" className="mb-3">
              Market Research
            </Title>
            <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
              For our market research, we searched for organizations and websites that assisted high school students in their
              process towards higher education. We compiled the resources into a list to be able to provide a link for our users.
              We also conducted interviews with representatives within our university's college assistance programs including the
              University of Washington Dream Project & The Pipeline Project.
              <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                We've learned that there are a lot of websites already out there with good information and content but lack the ability
                to present it in a way that will resonate with students of color, first generation students, or independent students.
              </Text>
            </Text>
            <Title variant="card" className="mb-3">
              Literature Review
            </Title>
            <Text variant="p" className="mb-4" style={{marginBottom: 30}}>
              For our literature review, we examined relevant academic journals that addresses:
              <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 3}}>
                <li>The magnitude of the problem</li>
                <li>The challenges and barriers</li>
                <li>The societal consequences of the problem</li>
              </ul>
              <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                Our review showed that parents with a lower socioeconomic status are significantly less likely to be involved in their
                child's schooling. They have limited informational, emotional, and financial resources available to assist their children.
                This leaves long term consequences for college eligibility due to high school achievement gaps. Their children are left
                on their own to plan for collegiate possibilities and finances.
              </Text>
            </Text>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
              03. Development
            </Title>
            <Text variant="p" className="mb-3" style={{marginBottom: 30}}>
              Through our user research and discussions with key stakeholders, we decided to build a website that provides higher education
              resources tailored towards an individual’s identity and struggles rather than a generic search engine. Students are able to  
              create an account and select up to four different identities:
              <ul className="list" style={{marginLeft: 40, marginBottom: 10, marginTop: 3}}>
                <li>First-Generation</li>
                <li>Low-Income</li>
                <li>Undocumented</li>
                <li>Student of Color</li>
              </ul>
              <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                Their choices are used to show resources tailored towards their identities. In addition to being the creators of the
                website, we also served as interim mentors. We created an email that allowed students to get in contact with us with the
                future goal of recruiting our fellow college students to join the mentoring program.
                <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                  Tools used to develop our website include:
                  <ul className="list" style={{marginLeft: 40, marginTop: 3}}>
                    <li>Front-end: <a href="https://reactjs.org/" target="_blank">React</a></li>
                    <li>Database: <a href="https://www.heroku.com/postgres" target="_blank">Heroku</a></li>
                    <li>User Accounts: <a href="https://firebase.google.com/docs/auth" target="_blank">Firebase Authentication</a></li>
                  </ul>
                </Text>
              </Text>
            </Text>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
              04. Usability Testing
            </Title>
            <Text variant="p" className="mb-3" style={{marginBottom: 30}}>
              I took the lead of conducting usability testing on our website prototype. Due to the COVID-19 pandemic, many aspects
              of our project had to be done remotely. Instead of conducting in-person usability tests, we used a website called
              <a href="https://www.usertesting.com/" target="_blank"> UserTesting</a>. This website contained tools that allowed us   
              to create test plans and distribute it to our specific target audience remotely. Our test participants were asked to
              explore our website and give their thoughts to our questions verbally and written out. We were able to view 
              each participant's screen recording as they navigated through our usability test.
              <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                We found that users loved the idea of being shown resources that are specific to their needs. They found the language
                and tone used for our resources page to be encouraging and helpful. Users expressed a desire to see a mission page
                that explains what our website aims to do. We immediately got on this and created a section for it on our landing page.
                If we were to continue this project in the future, we plan to build out our mentoring, personal statement, college
                applications, and financial aid pages.
              </Text>
            </Text>
            <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
              05. Outcomes
            </Title>
            <Text variant="p" className="mb-3" style={{marginBottom: 30}}>
              At the beginning of this project, we had a difficult time narrowing down our scope because we wanted to include as many
              features as possible. We wanted to provide a wide variety of tools that students may use in their journey towards higher
              education. To narrow down our scope, we pivoted towards building a website focused on providing resources tailored towards
              a student's identity.
              <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                In the middle of our project timeline, we had to shift entirely towards working remotely due to the COVID-19 pandemic.
                This proved to be challenging at first but we were able to swiftly adapt and stay on schedule with our project plans.
                Working as a UX Designer, I had the opportunity to conduct user testing and learned how valuable it was towards our
                project. Our test participants helped to identify issues within our website that would not have been obvious otherwise.
              </Text>
              <Text variant="p" className="mb-3" style={{marginTop: 10}}>
                Given more time, we would continue to work on expanding our mentorship feature and creating additional resource pages 
                for personal statements, higher education applications, and financial aid.
              </Text>
            </Text>
          </Container>
        </Section>
        <Section mt="5%" bg="dark">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/humaneos">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  HumaneOS
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
